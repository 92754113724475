<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				theme_custom {{perf}}
				<DataTable :value="themecustoms" class="p-datatable-sm" 
					v-model:filters="filters1" responsiveLayout="scroll" showGridlines stripedRows
          dataKey="id" :paginator="true" :rows="10"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          :globalFilterFields="['theme_name', 'meigara_cd_list', 'meigara_name_list_en', 'meigara_name_list_ja', 'theme_desc', 'u_date']"
					selectionMode="single">
					<template #header>
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="キーワード" />
						</span>
					</template>
					<Column field="id" header="No" :sortable="true"></Column>
					<Column field="theme_name" header="テーマ名ja" :sortable="true"></Column>
					<Column field="meigara_cd_list" header="銘柄コードリスト" :sortable="true"></Column>
					<Column field="meigara_name_list_en" header="銘柄リストen" :sortable="true"></Column>
					<Column field="meigara_name_list_ja" header="銘柄リストja" :sortable="true"></Column>
          <Column field="theme_desc" header="テーマ説明" :sortable="true"></Column>
          <Column field="u_date" header="情報更新日" :sortable="true"></Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import themecustomService from '@/service/ThemecustomService';

export default {
	data() {
		return {
			themecustoms: null,
			filters1: {},
			perf: null,
		}
	},
	themecustomService: null,
	created() {
		this.themecustomService = new themecustomService();
		this.initFilters1();
	},
	mounted() {
		this.themecustomService.getAll()
			.then(d => {
				this.themecustoms = d.data;
				this.perf = d.perf;
			}
		);	
	},
	methods: {
		initFilters1() {
			this.filters1 = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
			}
		}
	}
}
</script>

<style scoped>

</style>
